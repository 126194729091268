import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { ReactComponent as InstagramIcon } from '../img/social/instagram.svg'

import { ReactComponent as NWMS } from '../img/NWMS.svg'
import { ReactComponent as Nowmatters } from '../img/nowmatters.svg'
import { ReactComponent as Now } from '../img/now.svg'
import { ReactComponent as Matters } from '../img/matters.svg'
import Markdown from '../components/Markdown'
import Helmet from '../components/Helmet'
import AutofitImage from '../components/AutofitImage'
import Slider from '../../react-slick/lib'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HeroImage({ galleryImages, instagram, galleryImagesInterval, frameWidth, frameHeight, style }) {
  return (
    <span
      className="hero-image"
      style={style}
    >
      <a
        className="hero-image-link"
        title="facebook"
        href={instagram}
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon
          alt="Instagram"
        />
      </a>
      <Slider 
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        lazyLoad
        lazyLoadOffset={1}
        autoplay
        pauseOnHover
        autoplaySpeed={galleryImagesInterval || 5000}
      >
        {galleryImages.map((img, i) => (
          <AutofitImage
            key={i}
            frameWidth={frameWidth} 
            frameHeight={frameHeight} 
            image={img.image}
            alt={img.text}
          />
        ))}
      </Slider>
    </span>
  )
}

export class MaintenanceTemplate extends React.Component {
  render() {
    const {
      slogan = '',
      motto = '',
      address = '',
      addressMobile = '',
      addressLink = '',
      heading = '',
      headingMobile = '',
      galleryImages = [],
      galleryImagesInterval = 5000,
      instagram = '',
      description = '',
      descriptionMobile = '',
    } = this.props
    return (
      <>
        <Helmet />
        <span id="maintenance-page">
          <span className="desktop-only">
            <span className="inner-content">
              <span className="shadow"></span>
              <main className="header">
                  <Nowmatters />
              </main>
              <span className="row-01">
                <span className="slogan">
                  <Markdown>{slogan}</Markdown>
                  
                  <span className="sub-slogan">
                    <Now className="sub-slogan-now" />
                    <span className="sub-slogan-dash"><span></span></span>
                    <Matters className="sub-slogan-matters" />
                  </span>
                  
                </span>
                <HeroImage 
                  {...{galleryImages, instagram, galleryImagesInterval}} 
                  frameWidth="calc(var(--scale) * 3840px - (var(--maintenance-nowmatters-m-x) - var(--maintenance-inner-content-x)))"
                  frameHeight="calc(var(--scale) * 3840px - (var(--maintenance-nowmatters-m-x) - var(--maintenance-inner-content-x)) - var(--scale) * 192px)" />
                
              </span>
              <span className="row-02">
                <span className="logoContainer"><NWMS className="logo" /></span>
                <span className="mottoContainer">
                  <Markdown className="motto">{motto}</Markdown>
                  <a
                    className="address"
                    target="_blank"
                    href={addressLink}
                    rel="noopener noreferrer"
                  >
                    <Markdown>{address}</Markdown>
                  </a>
                </span>
                <span className="descContainer">
                  <Markdown className="contentHeader">{heading}</Markdown>
                  <Markdown className="description">{description}</Markdown>


                </span>
                
              </span>


            </span>
          </span> 
        
        
          <span className="mobile-only">
            <span className="inner-content">
              <main className="header">
                  <Nowmatters />
              </main>
              <span className="row-01">
                <span className="shadow"></span>
                <span className="slogan">
                  <Markdown>{slogan}</Markdown>
                  
                  <span className="sub-slogan">
                    <Now className="sub-slogan-now" />
                    <span className="sub-slogan-dash"><span></span></span>
                    <Matters className="sub-slogan-matters" />
                  </span>
                  
                </span>
              </span>
              <span className="row-02">
                <HeroImage 
                  {...{galleryImages, instagram, galleryImagesInterval}} 
                  frameWidth="calc(var(--scale) * 1143.4px)"
                  frameHeight="calc(var(--scale) * 1049.6px)" />
              </span>

              <span className="row-03">
                <span className="group-01">
                  <Markdown className="contentHeader">{headingMobile}</Markdown>
                  <Markdown className="motto">{motto}</Markdown>
                </span>
                <Markdown className="description">{descriptionMobile}</Markdown>
              </span>
                

              <span className="row-04">
                <a
                  className="address"
                  target="_blank"
                  href={addressLink}
                  rel="noopener noreferrer"
                >
                  <Markdown>{addressMobile}</Markdown>
                </a>
                <NWMS className="logo" />
              </span>


            </span>

          </span>
        </span>
      </>
    )
  }
}

// BrainhubCarousel BrainhubCarousel__track BrainhubCarousel__trackContainer

MaintenanceTemplate.propTypes = {}

const MaintenancePage = ({ data }) => {
  return <MaintenanceTemplate {...data.markdownRemark.frontmatter} />
}

MaintenancePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MaintenancePage

export const MaintenancePageQuery = graphql`
  query MaintenancePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        heading
        headingMobile
        description
        descriptionMobile

        galleryImages {
          image {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                transformOptions: { fit: COVER }
                quality: 50
                breakpoints: [750, 1080, 1366, 1920]
              )
            }
          }
          text
        }
        instagram
        address
        addressMobile
        addressLink
        slogan
        motto
      }
    }
  }
`
